<!--
 * @Author: 大年
 * @Date: 2022-09-09 11:18:06
 * @Description: 
-->
<template>
  <div class="menu_box">
    <ul>
      <li :class="activeFn(item, index)" v-for="(item, index) in list" :key="index">
        <router-link :to="item.routeName ? item.routeName : '/'">
          <img :src="getSelect(item)" alt="" />
          <p>{{ item.name }}</p>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import { reactive, toRefs } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "menuBox",
  components: {},
  setup(props, { emit }) {
    const route = useRoute();
    const state = reactive({
      list: [
        {
          name: "首页",
          icon: require("@/assets/images/tabbar/home1_0.png"),
          selectIcon: require("@/assets/images/tabbar/home1_1.png"),
          routeName: "",
          routeNameArr: ["Home"],
        },
        {
          name: "空间展示",
          icon: require("@/assets/images/tabbar/space1_0.png"),
          selectIcon: require("@/assets/images/tabbar/space1_1.png"),
          routeName: "Spaces",
          routeNameArr: ["Spaces"],
        },
        // {
        //   name: "合作报价",
        //   icon: require("@/assets/images/tabbar/price1_0.png"),
        //   selectIcon: require("@/assets/images/tabbar/price1_1.png"),
        //   routeName: "ServicePrice",
        //   routeNameArr: ["ServicePrice"],
        // },
        {
          name: "解决方案",
          icon: require("@/assets/images/tabbar/case1_0.png"),
          selectIcon: require("@/assets/images/tabbar/case1_1.png"),
          routeName: "Programme",
          routeNameArr: ["Programme"],
        },
        {
          name: "联系我们",
          icon: require("@/assets/images/tabbar/contact1_0.png"),
          selectIcon: require("@/assets/images/tabbar/contact1_1.png"),
          routeName: "Contact",
          routeNameArr: ["Contact"],
        },
        {
          name: "我的",
          icon: require("@/assets/images/tabbar/my1_0.png"),
          selectIcon: require("@/assets/images/tabbar/my1_1.png"),
          routeName: "User",
          routeNameArr: ["User", "UserSpaces", "UserOrder"],
        },
      ],
    });

    const getSelect = (item, index) => {
      if (item.routeNameArr.includes(route.name)) {
        return item.selectIcon;
      }
      return item.icon;
    };

    const activeFn = (item, index) => {
      if (item.routeNameArr.includes(route.name)) {
        return "active";
      }
      return "";
    };

    return {
      ...toRefs(state),
      getSelect,
      activeFn,
    };
  },
};
</script>

<style lang="scss" scoped>
.menu_box {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
  width: 100%;
  height: 150px;
  background: #f5f5f5;
  ul {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {
      flex: 1;
      text-align: center;
      img {
        width: 72px;
        height: 72px;
        margin: 0 auto;
      }
      p {
        font-size: 28px;
        color: #666;
      }
    }
    .active {
      p {
        color: #000000;
      }
    }
  }
}
</style>
