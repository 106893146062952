<!--
 * @Author: 大年
 * @Date: 2022-09-06 14:03:58
 * @Description: 合作伙伴
-->
<template>
  <div class="partner">
    <div class="web_container">
      <div class="title">
        <p>合作伙伴</p>
      </div>
      <ul class="flexsb">
        <li>
          <img src="@/assets/images/mwlogo.png" alt="" />
        </li>
        <li>
          <img src="@/assets/images/xwqlogo.png" alt="" />
        </li>
        <li>
          <img src="@/assets/images/hhjlogo.png" alt="" />
        </li>
        <li>
          <img src="@/assets/images/fxlogo.png" alt="" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartnerModal",
  setup() {},
};
</script>
<style lang="scss" scoped>
.partner {
  padding: 40px 0 30px 0;
  border-bottom: 1px solid #ececec;
  overflow: hidden;
  .web_container {
    padding: 0 30px 0 40px;
    .title {
      p {
        display: inline-block;
        font-size: 48px;
        font-weight: bold;
        color: #0a0330;
        padding-bottom: 26px;
        border-bottom: 6px solid #fd3e5b;
      }
    }
    ul {
      flex-wrap: wrap;
      padding-top: 70px;
      li {
        width: 322px;
        padding: 22px 16px;
        border: 1px solid #9e9e9e;
        border-radius: 10px 10px 10px 10px;
        margin-bottom: 30px;
        img {
          width: 290px;
          height: 94px;
        }
      }
    }
  }
}
</style>
