import axios from "../axios";

export function createUnionSessionWithQrScene(data) {
  return axios({
    url: "/auth/createUnionSessionWithQrScene",
    method: "POST",
    data,
  });
}
export function checkScanResult(data) {
  return axios({
    url: "/auth/checkScanResult",
    method: "POST",
    data,
  });
}
export function sceneList(data) {
  return axios({
    url: "/portal/scene/list",
    method: "POST",
    data,
  });
}
export function featuredSpaceCategory(data) {
  return axios({
    url: "/portal/featuredSpace/category",
    method: "POST",
    data,
  });
}
export function planList(data) {
  return axios({
    url: "/portal/plan/list",
    method: "POST",
    data,
  });
}
export function planUnifiedOrder(data) {
  return axios({
    url: "/portal/plan/unifiedOrder",
    method: "POST",
    data,
  });
}
export function checkPaymentStatus(data) {
  return axios({
    url: "/portal/plan/checkPaymentStatus",
    method: "POST",
    data,
  });
}
export function getAgreement(data) {
  return axios({
    url: "/common/getAgreement",
    method: "POST",
    data,
  });
}
export function getPrivacy(data) {
  return axios({
    url: "/common/getPrivacy",
    method: "POST",
    data,
  });
}
