import ApiService from "./api.service";

export function FeaturedSpaceList(data) {
  return ApiService.post("/portal/FeaturedSpace/list", data);
}
export function spaceCategory(data) {
  return ApiService.post("/portal/featuredSpace/category", data);
}
export function messageSubmit(data) {
  return ApiService.post("/portal/message/submit", data);
}
export function trackStatus(data) {
  return ApiService.post("/portal/message/trackStatus", data);
}
export function getHomeAdlist(data) {
  return ApiService.post("/portal/ad/list", data);
}
