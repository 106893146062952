<!--
 * @Author: 大年
 * @Date: 2022-09-06 14:10:01
 * @Description: 赋能数字藏品
-->
<template>
  <div class="service_scene">
    <div class="web_container wow slideInUp">
      <div class="module_title">
        <div class="top_max_text flex">
          <img src="@/assets/images/jg.png" alt="" />
          <span>赋能数字藏品</span>
        </div>
        <div class="bot_small_text">打造自有平台型元宇宙空间</div>
      </div>
      <div class="scene_banner">
        <van-swipe class="banner_swipe" indicator-color="#0A0330" autoplay="3000">
          <van-swipe-item>
            <div class="list">
              <div class="b_l_right">
                <img v-if="isWechat" src="@/assets/images/scene1_f.jpg" alt="" />
                <video
                  v-else
                  class="bgVideo"
                  disablepictureinpicture
                  disableremoteplayback
                  playsinline
                  controlslist="nodownload"
                  webkit-playsinline
                  muted
                  loop
                  autoplay
                  preload
                >
                  <source :src="sceneVideoPuted1" />
                </video>
              </div>
              <div class="b_l_left">
                <div class="icon">
                  <img src="@/assets/images/scene1.png" alt="" />
                </div>
                <div class="max_title">举办数字藏品展览<br />朋友聚会和现场活动</div>
              </div>
              <div class="sub_dec">专业的数字藏品3D展馆，用户参观过程语音交流，点击直达藏品平台购买藏品！</div>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="list">
              <div class="b_l_right">
                <img v-if="isWechat" src="@/assets/images/scene2_f.jpg" alt="" />
                <video
                  v-else
                  class="bgVideo"
                  disablepictureinpicture
                  disableremoteplayback
                  playsinline
                  controlslist="nodownload"
                  webkit-playsinline
                  muted
                  loop
                  autoplay
                  preload
                >
                  <source :src="sceneVideoPuted2" />
                </video>
              </div>
              <div class="b_l_left">
                <div class="icon">
                  <img src="@/assets/images/scene2.png" alt="" />
                </div>
                <div class="max_title">每个人的私密世界</div>
              </div>
              <div class="sub_dec">私人收藏室，专门的隐私空间，邀请朋友聚会Party</div>
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <div class="list">
              <div class="b_l_right">
                <img v-if="isWechat" src="@/assets/images/scene3_f.jpg" alt="" />
                <video
                  v-else
                  class="bgVideo"
                  disablepictureinpicture
                  disableremoteplayback
                  playsinline
                  controlslist="nodownload"
                  webkit-playsinline
                  muted
                  loop
                  autoplay
                  preload
                >
                  <source :src="sceneVideoPuted3" />
                </video>
              </div>
              <div class="b_l_left">
                <div class="icon">
                  <img src="@/assets/images/scene3.png" alt="" />
                </div>
                <div class="max_title">满足WEB3世界的所有想象</div>
              </div>
              <div class="sub_dec">无限星空，无限想象力，拥有元宇宙土地，成为宇宙主人！</div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, toRefs } from "vue";
import { getBrowserInfo } from "@/utils/util";
export default {
  name: "ServiceScene",
  setup() {
    const state = reactive({
      isWechat: false,
    });
    const sceneVideoPuted1 = computed(() => {
      return "https://xvr.oss-cn-hangzhou.aliyuncs.com/video/scene1.mp4";
    });
    const sceneVideoPuted2 = computed(() => {
      return "https://xvr.oss-cn-hangzhou.aliyuncs.com/video/scene2.mp4";
    });
    const sceneVideoPuted3 = computed(() => {
      return "https://xvr.oss-cn-hangzhou.aliyuncs.com/video/scene3.mp4";
    });

    onMounted(() => {
      let { wechat } = getBrowserInfo();
      state.isWechat = wechat;
    });

    return {
      ...toRefs(state),
      sceneVideoPuted1,
      sceneVideoPuted2,
      sceneVideoPuted3,
    };
  },
};
</script>
<style lang="scss" scoped>
.service_scene {
  padding-top: 56px;
  .module_title {
    text-align: center;
    .top_max_text {
      justify-content: center;
      span {
        font-size: 48px;
        font-weight: bold;
        color: #0a0330;
        margin-left: 12px;
      }
      img {
        width: 80px;
        height: 80px;
      }
    }
    .bot_small_text {
      font-size: 24px;
      color: #9e9e9e;
      line-height: 36px;
      padding: 0 110px;
      margin-top: 20px;
    }
  }
  .scene_banner {
    width: 100%;
    padding: 0 30px;
    margin-top: 20px;
    .banner_swipe {
      width: 100%;
      height: 1276px;
      background: #ffffff;
      box-shadow: 0px 6px 12px 2px rgba(0, 0, 0, 0.16);
      border-radius: 20px 20px 20px 20px;
      border: 2px solid #ececec;
      .list {
        width: 100%;
        padding: 30px 30px 0;
        .b_l_right {
          width: 100%;
          height: 760px;
          overflow: hidden;
          :deep(.bgVideo) {
            height: 100%;
            width: 100%;
            pointer-events: none;
            border: none;
            object-fit: cover;
            border-radius: 10px;
          }
          img {
            border-radius: 10px;
          }
        }
        .b_l_left {
          display: flex;
          align-items: center;
          margin-top: 50px;
          .icon {
            width: 120px;
            height: 120px;
          }
          .max_title {
            font-size: 40px;
            font-weight: bold;
            color: #0a0330;
            line-height: 60px;
            margin-left: 30px;
          }
        }
        .sub_dec {
          font-size: 28px;
          color: #4a4a4a;
          line-height: 36px;
          margin-top: 30px;
        }
      }
      :deep(.van-swipe__indicators) {
        bottom: 40px;
        .van-swipe__indicator {
          background-color: #9e9e9e;
        }
      }
    }
  }
}
</style>
