<!--
 * @Author: 大年
 * @Date: 2022-09-06 14:19:47
 * @Description: 空间展示
-->

<template>
  <div class="space_display offsetTop">
    <div class="web_container wow slideInUp">
      <div class="module_title">
        <div class="top_max_text flex">
          <img src="@/assets/images/kjzs.png" alt="" />
          <span>搭建属于自己元宇宙平台</span>
        </div>
        <div class="bot_small_text">零代码搭建元宇宙平台，无需研发人员，灵活部署，百种场景任选，免费试用！</div>
      </div>
      <div class="display_list">
        <List :list="list" />
        <div @click="moreSee" class="enter_btn">更多场景</div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import { sceneList } from "@/api/w-axios/modules/home.js";
import List from "./SpaceList.vue";
import { useRouter } from "vue-router";

export default {
  name: "SpaceDisplay",
  components: {
    List,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const state = reactive({
      list: [],
    });

    const FeaturedSpaceListFn = async () => {
      try {
        let {
          result: { items },
        } = await sceneList({ page: 1, page_size: 12 });
        if (items.length) {
          state.list.push(...items);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const moreSee = () => {
      router.push({
        name: "Spaces",
      });
    };

    onMounted(() => {
      FeaturedSpaceListFn();
    });

    return {
      ...toRefs(state),
      moreSee,
    };
  },
};
</script>
<style lang="scss" scoped>
.space_display {
  position: relative;
  padding-top: 70px;
  background-color: #fff;
  overflow: hidden;
  .module_title {
    text-align: center;
    .top_max_text {
      justify-content: center;
      span {
        font-size: 48px;
        font-weight: bold;
        color: #0a0330;
        margin-left: 12px;
      }
      img {
        width: 92px;
        height: 66px;
      }
    }
    .bot_small_text {
      font-size: 24px;
      color: #9e9e9e;
      line-height: 36px;
      padding: 0 110px;
      margin-top: 20px;
    }
  }
  .display_list {
    position: relative;
    margin-top: 70px;
    .enter_btn {
      width: 440px;
      line-height: 92px;
      background: #1d2eda;
      border-radius: 20px 20px 20px 20px;
      font-size: 32px;
      color: #ffffff;
      text-align: center;
      margin: 0 auto;
    }
  }
}
</style>
