import axios from "../axios";

export function createUnionSessionWithQrScene(data) {
  return axios({
    url: "/auth/createUnionSessionWithQrScene",
    method: "POST",
    data: { ...data, from: "portal" },
  });
}
export function checkScanResult(data) {
  return axios({
    url: "/auth/checkScanResult",
    method: "POST",
    data: { ...data, from: "portal" },
  });
}
export function loginWithPassword(data) {
  return axios({
    url: "/auth/loginWithPassword",
    method: "POST",
    data: { ...data, from: "portal" },
  });
}
export function smsSend(data) {
  return axios({
    url: "/sms/send",
    method: "POST",
    data,
  });
}

export function userRegister(data) {
  return axios({
    url: "/common/userRegister",
    method: "POST",
    data,
  });
}
