/*
 * @Author: 大年
 * @Date: 2022-08-29 10:48:00
 * @Description:
 */
import { createRouter, createWebHistory } from "vue-router";
import home from "../views/home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: home,
  },
  {
    path: "/spaces",
    name: "Spaces",
    component: () => import("@/views/space.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/views/contact.vue"),
  },
  {
    path: "/programme",
    name: "Programme",
    component: () => import("@/views/programme/index.vue"),
  },
  {
    path: "/servicePrice",
    name: "ServicePrice",
    component: () => import("@/views/ServicePrice.vue"),
  },
  {
    path: "/extension",
    name: "Extension",
    component: () => import("@/views/extension.vue"),
  },
  {
    path: "/user",
    name: "User",
    component: () => import("@/views/user.vue"),
  },
  {
    path: "/userSpaces",
    name: "UserSpaces",
    component: () => import("@/views/UserSpaces.vue"),
  },
  {
    path: "/userOrder",
    name: "UserOrder",
    component: () => import("@/views/UserOrder.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    return {
      left: 0,
      top: 0,
    };
  },
});

export default router;
