<!--
 * @Author: 大年
 * @Date: 2022-09-06 14:14:54
 * @Description: banner
-->
<template>
  <div class="top_banner offsetTop">
    <ComHeader @navJump="navJump" :p_current="navCurrent" />
    <!-- <div class="muiBox-home">
      <video class="bgVideo" disablepictureinpicture disableremoteplayback playsinline controlslist="nodownload" webkit-playsinline muted loop autoplay preload>
        <source :src="videoPuted" />
      </video> 
    </div> -->
    <div class="fixed_bgcolor">
      <div class="website_title">
        <img src="@/assets/images/websiteLogo.png" alt="" />
        <span>XVR元宇宙</span>
      </div>
      <div class="banner_box">
        <van-swipe autoplay="3000" indicator-color="#0A0330">
          <van-swipe-item v-for="(item, index) in list" :key="index">
            <div class="banner_list">
              <div class="custom_bg" :style="{ backgroundImage: `url(${item.mobile_cover})` }"></div>
              <div class="web_container">
                <div class="slogn">
                  <p class="slogn_cn">{{ item.description1 }}</p>
                  <!-- <p class="slogn_en">元宇宙VR平台系统源头厂家</p> -->
                </div>
                <div class="btn_wrap">
                  <!-- <div @click="bannerChangeValue" class="connect_service">
                    <img src="@/assets/images/create.png" alt="" />
                  </div> -->
                  <div class="custom_btn">
                    <a v-if="item.button_url.indexOf('https') > -1" :href="item.button_url" target="_blank">{{ item.button_title }}</a>
                    <a v-else @click="navContact(item.button_url)" href="javascirpt:viod(0)">{{ item.button_title }}</a>
                  </div>
                </div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs, onMounted } from "vue";
import ComHeader from "@/components/ComHeader.vue";
import { useRouter } from "vue-router";
import { jupUrl } from "@/utils/utils";
import { getHomeAdlist } from "@/api/home.service";
export default {
  name: "BannerVideo",
  components: {
    ComHeader,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const state = reactive({
      navCurrent: 0,
      list: [],
    });
    const videoPuted = computed(() => {
      return "https://xvr.oss-cn-hangzhou.aliyuncs.com/video/banner.mp4";
    });

    const navJump = (index) => {
      emit("navJump", index);
    };

    const bannerChangeValue = () => {
      jupUrl("Spaces");
    };

    const getHomeAdlistFn = async () => {
      try {
        const {
          result: { items },
        } = await getHomeAdlist();
        state.list.push(...items);
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(() => {
      getHomeAdlistFn();
    });

    const navContact = (name) => {
      router.push({
        name,
      });
    };

    return {
      ...toRefs(state),
      navJump,
      videoPuted,
      bannerChangeValue,
      navContact,
    };
  },
};
</script>
<style lang="scss" scoped>
.top_banner {
  // position: relative;
  height: 1100px;
  margin: 0 auto;
  .muiBox-home {
    position: absolute;
    // z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border: none;
    background: #000;
    :deep(.bgVideo) {
      height: 100%;
      width: 100%;
      pointer-events: none;
      border: none;
      object-fit: cover;
    }
  }
  .fixed_bgcolor {
    position: relative;
    // position: absolute;
    // left: 0;
    // top: 0;
    width: 100%;
    height: 100%;
    background: rgba(114, 0, 125, 0.63);
    // padding-top: 80px;
    .website_title {
      position: absolute;
      left: 50%;
      top: 100px;
      transform: translate(-50%, 0);
      z-index: 19;
      img {
        width: 200px;
        height: 200px;
        margin: 48px auto 0;
      }
      span {
        font-size: 72px;
        font-weight: bold;
        color: #ffffff;
      }
    }
    .banner_box {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      .van-swipe {
        width: 100%;
        height: 100%;
      }
      .banner_list {
        position: relative;
        width: 100%;
        height: 100%;
        .custom_bg {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
    .web_container {
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 100%;
      height: 100%;
      padding-top: 450px;
      background-color: rgba(114, 0, 125, 0.35);
      .slogn {
        text-align: center;
        margin-top: 30px;
        &_cn {
          font-size: 40px;
          color: #ffffff;
          padding: 0 36px;
          line-height: 60px;
        }
        &_en {
          font-size: 28px;
          color: #ffffff;
          margin-top: 30px;
        }
      }
      .btn_wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        margin-top: 40px;
        // .connect_service {
        //   width: 200px;
        //   height: 213px;
        //   img{
        //     width: 100%;
        //     height: 100%;
        //   }
        // }
        .custom_btn {
          a {
            display: inline-block;
            width: 300px;
            line-height: 72px;
            background-color: #07f;
            box-shadow: 0 15px 35px 0 rgb(34 136 255 / 30%);
            border-radius: 52px;
            color: #fff;
            text-align: center;
            font-size: 24px;
          }
        }
      }
    }
  }
}
</style>
