import axios from "axios";
import qs from "qs";
import store from "@/store";
// import router from "@/router";
import { LOGOUT } from "@/store/mutation.types";
import { clearToken } from "@/utils/util";
const currentUser = JSON.parse(window.localStorage.getItem("currentUser"));

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    // 设置全局的baseURL
    axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

    // 全局将对象数据转换为queryString数据, 这样提交到后台的数据是表单, 而不是json
    // axios 会根据数据自动设置 header 的 Content-type, 无需手动设置此header
    // 取消全局设置, 因为会和文件上传冲突, 在调用post时直接使用 qs.stringify() 即可
    // axios.defaults.transformRequest = [
    //   (data) => {
    //     return qs.stringify(data);
    //   },
    // ];

    // 允许携带 cookie, 以实现 session 功能
    // axios.defaults.withCredentials = true;

    // 添加请求拦截器, 在 header 里携带 access_token 给后端
    axios.interceptors.request.use(
      function (config) {
        config.headers = {
          ...config.headers,
          "Access-Token": currentUser ? currentUser.access_token : "",
        };
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    // 添加响应拦截, 未登录的情况跳转到登录页面
    axios.interceptors.response.use(
      function (response) {
        // 2xx
        return response.data;
      },
      function (error) {
        // not 2xx
        if (error.response.status == 401) {
          clearToken();
          store
            .dispatch(LOGOUT)
            .then(() => {
              // 由于 Bootstrap 模态遮罩层无法隐藏, 暂时只接跳转
              window.location.href = "/login?action=api-response-401";
              // router.push({
              //   name: "Login",
              //   query: {
              //     action: "api-response-401",
              //   },
              // });
            })
            .catch((error) => {
              console.log(error);
              window.location.href = "/login?action=api-response-401";
            });
        }

        return Promise.reject(error.response);
      }
    );
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return axios.post(resource, qs.stringify({ ...params, __from: "portal" }));
  },

  /**
   * Set the POST HTTP request for upload file
   * @param resource
   * @param formData
   * @returns {*}
   */
  upload(resource, formData) {
    return axios.post(resource, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

ApiService.init();

export default ApiService;
