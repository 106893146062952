import { loginWithPassword } from "@/api/w-axios/modules/login.js";
import { Toast } from "vant";
import { jupUrl } from "@/utils/utils.js";
const state = {
  user: JSON.parse(window.localStorage.getItem("user")) || "",

  loginTab: false,
};

const mutations = {
  SET_USER(state, user) {
    state.user = user;
    window.localStorage.setItem("user", JSON.stringify(user));
  },

  CHANGE_LOGIN_TAB(state, flag) {
    if (flag && state.user) {
      return;
    }
    state.loginTab = flag;
  },
  DELETE_USER(state) {
    state.user = "";
    window.localStorage.removeItem("user");
  },
};

const actions = {
  setUser({ commit }, user) {
    commit("SET_USER", user);
  },
  deleteUser({ commit }) {
    commit("DELETE_USER");
  },
  changeLoginTab({ commit }, flag) {
    commit("CHANGE_LOGIN_TAB", flag);
  },
  login({ commit }, val) {
    return new Promise((resolve, reject) => {
      loginWithPassword(val)
        .then((res) => {
          commit("SET_USER", res.result);
          commit("CHANGE_LOGIN_TAB", false);
          resolve();
          Toast("登录成功");
        })
        .catch((err) => {
          let msg = err.data.msg;
          msg = msg.replace(/\([\d+)]*\)/g, "");
          Toast(msg);
          reject();
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
