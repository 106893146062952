// login action types
export const LOGIN = "login"; // 原始登陆方法
export const LOGIN_WITH_PASSWORD = "loginWithPassword"; // 新的登陆方法
export const CHECK_SCAN_RESULT = "checkScanResult";
export const LOGOUT = "logout";
export const VERIFY_AUTH = "verifyAuth";

// login mutation types
export const SET_AUTH = "setUser";
export const PURGE_AUTH = "logOut";
export const FORCE_LOGIN = "forceSmsLogin";
