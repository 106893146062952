<!--
 * @Author: 大年
 * @Date: 2022-09-05 14:22:52
 * @Description: 
-->
<template>
  <div class="com_footer">
    <div class="gif_anime">
      <img src="@/assets/images/footerbg.png" alt="" />
    </div>
    <div class="pos_content">
      <div class="top_content">
        <div class="web_container">
          <div class="top_c">
            <div class="footer_logo">
              <img src="@/assets/images/footerLogo.png" alt="" />
            </div>
            <div class="support_platform">
              <p class="sub_title">平台支持</p>
              <div class="list">
                <p class="item">
                  <img src="@/assets/images/mobile.png" alt="" />
                  <span>手机</span>
                </p>
                <p class="item mg30">
                  <img src="@/assets/images/pc.png" alt="" />
                  <span>电脑</span>
                </p>
                <p class="item">
                  <img src="@/assets/images/vr.png" alt="" />
                  <span>VR</span>
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="right_content">
            <div class="about_ours">
              <p class="sub_title">关于我们</p>
              <div class="list">
                <p>技术团队</p>
                <p>合作伙伴</p>
              </div>
            </div>
            <div class="connect_info about_ours">
              <p class="sub_title">联系我们</p>
              <div class="list">
                <p>客服热线：+86 400 0000 000</p>
                <p>Q Q 号：888888</p>
              </div>
            </div>
          </div>
          <div class="code_wrap">
            <div class="items">
              <img src="@/assets/images/official.png" alt="" />
              <span>微信公众号</span>
            </div>
          </div> -->
        </div>
      </div>
      <div class="bot_content">
        <div class="web_container">Copyright © 2022 XVR.ART <a href="https://beian.miit.gov.cn/" target="_blank">琼ICP备11002133号-15</a></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComFooter",
  props: {
    msg: String,
  },
};
</script>

<style scoped lang="scss">
.com_footer {
  position: relative;
  margin-top: 75px;
  .gif_anime {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 330px;
  }
  .pos_content {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    .top_content {
      padding-bottom: 55px;
      border-bottom: 1px solid #ececec;
      .web_container {
        .top_c {
          display: flex;
          justify-content: space-between;
          padding: 0 140px 0 46px;
          .footer_logo {
            width: 190px;
            height: 190px;
          }
          .support_platform {
            .sub_title {
              font-size: 18px;
              color: #0a0330;
            }
            .list {
              display: flex;
              margin-top: 10px;
              .item {
                text-align: center;
                img {
                  width: 74px;
                  height: 74px;
                  margin-bottom: 4px;
                }
                span {
                  font-size: 22px;
                  color: #0a0330;
                }
              }
              .mg30 {
                margin: 0 30px;
              }
            }
          }
        }

        .right_content {
          display: flex;
          justify-content: space-between;
          padding: 0 50px;
          .about_ours {
            margin-top: 110px;
            .sub_title {
              font-size: 36px;
              color: #0a0330;
            }
            .list {
              margin-top: 30px;
              p {
                font-size: 28px;
                color: #0a0330;
                line-height: 64px;
              }
            }
          }
        }
        .code_wrap {
          .items {
            text-align: center;
            img {
              width: 204px;
              height: 204px;
              margin: 100px auto 8px;
            }
            span {
              font-size: 28px;
              color: #0a0330;
            }
          }
        }
      }
    }
    .bot_content {
      padding: 40px 0;
      font-size: 24px;
      color: #0a0330;
      text-align: center;
      .web_container {
        a {
          padding-bottom: 6px;
          color: #0a0330;
          &:hover {
            border-bottom: 2px solid #0a0330;
          }
        }
      }
    }
  }
}
</style>
