<template>
  <div class="title_main">
    <!-- <Checkbox class="check_box" v-model="checked" direction="horizontal" @change="onChange" checked-color="#000">
    </Checkbox> -->
    <div style="text-align: center">
      {{ title }}<span class="text-danger" @click="userClick" style="color: #19a0f1">《使用条款》</span
      ><span class="text-danger" @click="itemClick" style="color: #19a0f1">《隐私政策》</span>
    </div>
    <Overlay :show="show" :lock-scroll="false" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="close_main">
            《使用条款》
            <Icon class="close_icon" name="cross" @click="show = false" />
          </div>
          <div class="scroll_main">
            <div class="html_item" v-html="value"></div>
          </div>
        </div>
      </div>
    </Overlay>

    <Overlay :show="visible" :lock-scroll="false" @click="visible = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="close_main">
            《隐私政策》
            <Icon class="close_icon" name="cross" @click="visible = false" />
          </div>
          <div class="scroll_main">
            <div class="html_item" v-html="detail"></div>
          </div>
        </div>
      </div>
    </Overlay>
  </div>
</template>
<script setup>
const emit = defineEmits(["updated"]);
import { ref } from "vue";
import { Overlay, Icon, Checkbox } from "vant";
import { getAgreement, getPrivacy } from "@/api/w-axios/modules/home.js";
const props = defineProps(["title"]);
const show = ref(false);
const value = ref("");
async function userClick() {
  if (!value.value) {
    const { result } = await getAgreement();
    value.value = result.agreement;
    show.value = true;
  } else {
    show.value = true;
  }
}

const visible = ref(false);
const detail = ref("");
async function itemClick() {
  if (!detail.value) {
    const { result } = await getPrivacy();
    detail.value = result.privacy;
    visible.value = true;
  } else {
    visible.value = true;
  }
}
const checked = ref(false);
function onChange(val) {
  emit("updated", val);
}
</script>
<style lang="scss" scoped>
.title_main {
  // display: flex;
  z-index: 100;
  position: relative;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 100%;
  margin: 0 20px;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  .scroll_main {
    padding: 0 12px;
    max-height: 70vh;
    overflow-y: auto;
  }
  .close_main {
    font-weight: bold;
    font-size: 14px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    position: relative;
    .close_icon {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      line-height: 30px;
      padding: 0 8px;
    }
  }
}

.html_item {
  :deep() p {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 20px;
    font-size: 12px;
  }
}
</style>
